body {
  overflow: hidden;
}

h1 {
  background: #272822;
  bottom: 0;
  color: #fff;
  font-size: 2em;
  margin: auto 40px auto auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 30%;
}

canvas {
  height: 100vh;
  width: 100vw;
}

.nav {
  height: 70px;
  width: 100vw;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  margin: auto;
}

.nav img {
  padding: 20px;
  width: 200px;
}

.main-content {
  z-index: 10;
  width: 70vw;
  height: 80vh;
  color: white;
  background: #272822;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px !important;
  box-shadow: 0 16px 40px -5px rgba(0, 0, 0, 0.5);
  display: inline-block;
  font-size: 0.8rem !important;
  /*padding: 40px 50px 40px 45px;*/
  pointer-events: all;
  white-space: pre-wrap !important;
}

@media only screen and (max-width: 600px) {
  .main-content {
    margin: 0;
    width: 100vw;
    height: 100vh;
  }
}

.main-content-close {
  font-size: 2rem;
  position: absolute;
  right: 19px;
  top: 10px;
  color: white;
  font-weight: bold;
  z-index: 10;
}

.canvas3d {
}

.button-container {
  width: 550px;
  text-align: center;
  font-size: 2em;
  color: white;
  position: absolute;
  right: 0;
  top: 65%;
  bottom: 0;
  margin: auto;
}

.button-container {
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .button-container {
    display: none;
    width: 100%;
    text-align: center;
    font-size: 2em;
    color: white;
    position: absolute;
    right: 0;
    top: 83%;
    bottom: 0;
    margin: auto;
  }

  h1 {
    text-align: center;
    font-size: 1.5rem;
    /* text-align: right; */
    color: white;
    background: #272822;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: auto;
    margin-right: 0;
  }

  h1 span{
    font-size: 3rem !important;
  }
}

.button-container button {
  background-color: rgb(255 255 255 / 0%);
  width: 50%;
  height: 42px;
  border-radius: 10px;
  font-size: 1em;
  color: white;
}

.music-player {
  border: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 350px;
  z-index: 1;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid white;
  z-index: 2;
}

.stats {
  right: 0px !important;
  left: unset !important;
}

.pressEsc {
  color: white;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.25);
  width: 300px;
  top: 20px;
  height: 20px;
  text-align: center;
  padding: 10px;
  z-index: 30;
}

